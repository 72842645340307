import React, { useContext } from "react";
import { FaWhatsapp } from "react-icons/fa";
import StarRatings from "react-star-ratings";
import { Context } from "../../../../../Context_holder";
import axios from "axios";

function UniversityBanner({ collegeDetails }) {
  const { setapply_popUpIsOpen, user, setuserSignUp_popup } = useContext(Context);

  const enquiry_api = () => {
    if (user) {
      axios
        .patch(
          process.env.REACT_APP_API_BASE_URL +
            process.env.REACT_APP_USER_URL +
            "college_edit/" +
            user?._id +
            "/" +
            collegeDetails._id
        )
        .then((success) => {
          if (success.data.status === 1) {
            setapply_popUpIsOpen(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setuserSignUp_popup(true);
    }
  };

  return (
    <section className="relative bg-[#18092f] text-white py-8 w-full">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between gap-[4rem]">
        {/* Text Section */}
        <div className="lg:w-1/2 text-left px-4">
          <div className="text-2xl font-bold mb-4">
            <p className="text-3xl lg:text-4xl">{collegeDetails.college_name}</p>
          </div>

          <div className="flex gap-2 mb-4">
            <span className="text-sm lg:text-base">Approved By</span>
            <div className="font-bold text-black px-2 rounded-xl overflow-hidden relative">
              <div className="inline-block animate-marquee whitespace-nowrap text-white ml-2">
                {collegeDetails.affiliatedTo}
              </div>
            </div>
          </div>

          {/* Mobile Image Section */}
          <div className="lg:hidden mb-4">
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}image/college_logo/${collegeDetails.university_banner[0]}`}
              alt="University Banner"
              className="w-full max-w-lg rounded-lg shadow-lg"
            />
          </div>

          {/* Features List */}
          <div className="mb-4 flex flex-col md:flex-row gap-4 md:gap-2 ">
            <div className="flex items-center gap-2  ">
              <svg
                className="w-6 h-6 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 16h.01M12 16h.01M16 16h.01M9 20h6M5 8l7-7 7 7M5 8v12a2 2 0 002 2h10a2 2 0 002-2V8"
                ></path>
              </svg>
              <span className="text-sm ">
                Lifetime Courses From Top Instructor
              </span>
            </div>
            <div className="flex items-center gap-2">
              <svg
                className="w-6 h-6 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 12c2.28 0 4.34.69 6.04 1.87M12 16v4m0-4h-4m4 0h4m-6-6a4 4 0 118 0 4 4 0 01-8 0zm0-4a4 4 0 100 8 4 4 0 000-8zM12 14l3 2-3 2"
                ></path>
              </svg>
              <span className="text-sm  w-[100%]">
                Over 15,000 Courses
              </span>
            </div>
          </div>

          <div className="mb-6 md:flex gap-4 items-center">
            <button
              className=" order-0 bg-yellow-400 text-black font-semibold py-2 px-6 rounded-md hover:bg-yellow-500 transition duration-300"
              onClick={enquiry_api}
            >
              Get Started for Apply
            </button>
            <div className="flex items-center gap-2 order-1">
              <StarRatings
                rating={collegeDetails.avgCollegeRating ?? 0}
                starRatedColor="gold"
                numberOfStars={5}
                starDimension="20px"
                starSpacing="2px"
                readonly
              />
              <div className="text-white text-sm">
                ({collegeDetails.collegeReviews.length} reviews)
              </div>
            </div>
          </div>

          <div className="flex gap-4 mb-8">
            <button
              type="button"
              className="bg-blue-700 hover:bg-red-800 text-white font-medium rounded-lg text-sm px-5 py-2.5"
            >
              <a
                href={`tel:+91${collegeDetails.contactNumber}`}
                className="no-underline text-white"
              >
                Call now
              </a>
            </button>
            <button
              type="button"
              className="bg-green-500 hover:bg-green-600 text-white font-medium rounded-lg text-sm px-5 py-2.5"
            >
              <a
                href={`https://wa.me/${collegeDetails.contactNumber}`}
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline flex items-center gap-1"
              >
                <span className="text-[11px] text-white font-bold">Chat now</span>
                <FaWhatsapp />
              </a>
            </button>
          </div>
        </div>

        {/* Image Section (Desktop Version) */}
        <div className="lg:w-1/2 hidden lg:block px-4">
          <img
            src={`${process.env.REACT_APP_API_BASE_URL}image/college_logo/${collegeDetails.university_banner[0]}`}
            alt="University Banner"
            className="w-full max-w-lg rounded-lg shadow-lg"
          />
        </div>
      </div>
    </section>
  );
}

export default UniversityBanner;
