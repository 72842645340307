import React from "react";
import Container from "../container/Container";

const OurConnection = ({ collegeDetails }) => {
  return (
    <Container fluid extraclassName="md:py-8 py-2 bg-[#f78c45]">
      <div className="md:w-[70%] w-full  mx-auto">
        <h1 className="py-1 text-center text-black font-semibold px-10 bg-white uppercase lg:text-[40px] md:text-[30px] text-[18px]">
          Our Students Success Story
        </h1>
        <div className="md:flex  md:py-14 py-2 justify-between  flex text-center ">
          <div className="lg:w-[30%]  w-[30%]   sm:w-[48%] md:w-[30%]">
            <p className="font-bold text-[35px] text-red-700 text-sm sm:text-base md:text-[35px]">
              124000+
            </p>
            <p className="font-semibold text-[10px] sm:text-base md:text-[16px]">
              Students Enrolled
            </p>
          </div>
          <div className="lg:w-[30%] w-[30%]   sm:w-[48%] md:w-[30%]">
            <p className="font-bold text-[35px] text-red-700 text-sm sm:text-base md:text-[35px]">
              {collegeDetails.registered_instructors}+
            </p>
            <p className="font-semibold text-[10px] sm:text-base md:text-[16px]">
              Registered Instructors
            </p>
          </div>
          <div className="lg:w-[30%] w-[30%] sm:w-[48%] md:w-[30%]">
            <p className="font-bold text-[35px] text-red-700 text-sm sm:text-base md:text-[35px]">
              98%
            </p>
            <p className="font-semibold text-[10px] sm:text-base md:text-[16px]">
              Success Rate
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default OurConnection;
