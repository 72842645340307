import axios from "axios";
import React, { useContext } from "react";
// import Select from 'react-select';
import { Context } from "../../../../Context_holder";

import "react-quill/dist/quill.snow.css";

export default function Banner_add() {
  const { token, notify } = useContext(Context);

  const submit_handler = (e) => {
    e.preventDefault();

    const banner = e.target.banner.files;

    const formData = new FormData();

    for (let data of banner) {
      formData.append("banner", data);
    }

    axios
      .post(
        process.env.REACT_APP_API_BASE_URL +
          process.env.REACT_APP_BANNER_URL +
          "add",
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      )

      .then((success) => {
        notify(success.data.msg, success.data.status);

        if (success.data.status === 1) {
          e.target.reset();
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="min-h-screen py-10 bg-gray-300">
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
        <div className="text-center text-4xl font-bold mb-3"> Banners Add</div>
        <form onSubmit={submit_handler} enctype="multipart/form-data">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="banner"
              >
                Banners
              </label>
              <input
                multiple={true}
                type="file"
                id="banner"
                name="banner"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>

          <div className="flex justify-end mb-4 mt-2">
            <button
              type="submit"
              className="bg-blue-500 a hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
