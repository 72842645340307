
import React, { useContext, useEffect, useState } from 'react'

import { Context } from '../../../../Context_holder';
import Select from 'react-select';
import axios from 'axios';

import 'react-quill/dist/quill.snow.css';
import ReactStarsRating from 'react-awesome-stars-rating';



export default function PlacementScore_add() {
  const{college_fetch,colleges ,selected_college,setselected_college,notify}=useContext(Context)








useEffect(
    ()=>{
college_fetch()
    },[]
)


const handleSubmit=(e)=>{
  e.preventDefault();

    const college_id= selected_college?.value
    const year= e.target.year.value
    const placementScore= e.target.score.value


    const data= {
        college_id:college_id,
        year:year,
        placementScore:placementScore

    }

    

    axios.post(process.env.REACT_APP_API_BASE_URL+process.env.REACT_APP_PLACEMENT_SCORE_URL+"add",data)
    .then((success) => {
      
    
      notify(success.data.msg,success.data.status)
      
  
      if (success.data.status === 1) {
       
  
        
  
        setselected_college(null)
        
      e.target.reset()
  
      }
    })
  
    .catch((error) => {
      console.error('Error:', error);
    });
  



}


  return (
    <div className="min-h-screen py-10 bg-gray-300">
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4">Placement Scores</h2>
      
      <form action="" onSubmit={handleSubmit}>
      <div>
      <label htmlFor="college_id" className="block text-sm font-medium text-gray-700">College ID</label>
      <Select
        value={selected_college}
        styles={{
          control: (provided) => ({
            ...provided,
            padding: '4px 20px',
            marginTop: '4px',
          }),
        }}
        onChange={(e) => { setselected_college(e) }}
        name="college_id"
        options={colleges?. map(data => ({ value: data._id, label: data.college_name }))}
      />
    </div>


    <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="year"> Year</label>
          <input
            id="year"
            name="year"
            type="number"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Year"
          />
        </div>


        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="score"> Score</label>
          <input
            id="score"
            name="score"
            type="number"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Score"
          />
        </div>
      
    
     
   
    
      <button
           type='submit'   
              className="bg-blue-500  my-3 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              
            >
              Submit
            </button>

      </form>

    
    </div>
  </div>
  
  );
}


