








import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../Context_holder';
import Select from 'react-select';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactStarsRating from 'react-awesome-stars-rating';



export default function Review_add() {

  const{college_fetch,colleges ,selected_college,setselected_college,notify}=useContext(Context)








useEffect(
    ()=>{
college_fetch()
    },[]
)


const handleSubmit=(e)=>{
    e.preventDefault();

    const college_id= selected_college.value
    const review= e.target.review.value


    const data= {

        college_id:college_id,
        review:review
    }


    axios.post(process.env.REACT_APP_API_BASE_URL+process.env.REACT_APP_REVIEW_URL+"add",data)
    .then((success) => {
      
      notify(success.data.msg,success.data.status)
  
      if (success.data.status === 1) {
    
        e.target.reset();
        setselected_college(null)
        
        
  
      }
    })
  
    .catch((error) => {
      console.error('Error:', error);
    });
  



}


  return (
    <div className="min-h-screen py-10 bg-gray-300">
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4"> Review </h2>
      
<form action="" onSubmit={handleSubmit}>


   <div>
      <label htmlFor="college_id" className="block text-sm font-medium text-gray-700">College ID</label>
      <Select
        value={selected_college}
        styles={{
          control: (provided) => ({
            ...provided,
            padding: '4px 20px',
            marginTop: '4px',
          }),
        }}
        onChange={(e) => { setselected_college(e) }}
        name="college_id"
        options={colleges?.map(data => ({ value: data._id, label: data.college_name }))}
      />
    </div>

    <div className='my-2'>
      <label htmlFor="college_id" className="block text-sm my-2 font-medium text-gray-700">Review</label>


     <textarea
            id="review"
            name="review"
            className="shadow appearance-none border rounded w-full py-2 px-3  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Review"
          ></textarea>
    </div>

<button
      type='submit'
              
              className="bg-blue-500  my-3 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              
            >
              Submit
            </button>
</form>
     


      
    
     
   
    
      
    </div>
  </div>
  
  );
}


