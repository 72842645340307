import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../context/universitycontext";

function LoanFec({ collegeDetails }) {


  return (
    <div className="w-full py-5  px-4 lg:px-0 mt-2">
      <div className="container mx-auto">
        <h2 className="text-white py-2 my-2 md:text-[2rem] text-xl font-bold text-yellow-300">Education Loan Facility</h2>
        <p className="md:text-[14px] text-[12px]">{collegeDetails.education_loan}</p>
        <ul className="pl-0">
          <li className="font-bold text-yellow-400">Contact</li>
          <li>Education Loan Coordinator</li>
         
          <li>
            Mob : <Link className="font-bold text-yellow-400 text-sm" to={`tel:${collegeDetails.loan_contact}`}>{collegeDetails.loan_contact}</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default LoanFec;
