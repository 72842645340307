




import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select';
import { Context } from '../../../../Context_holder';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export default function Story_add() {

    const{college_fetch,colleges,selected_college,setselected_college,token,notify }=useContext(Context)
    



    useEffect(
        ()=>{
      
          college_fetch()
          setselected_college(null)
      
        
        },[]
      )
     

    const submit_handler = (e)=>{

        e.preventDefault();
        
        
    
    
     const college_id= selected_college?.value
     
     
     const title = e.target.title.value 
     const video_url=e.target.url.value 

     const data={
      college_id:college_id,
      title:title,
      video_url:video_url

     }
    
    
     
    
      axios.post(process.env.REACT_APP_API_BASE_URL+process.env.REACT_APP_STORY_URL+"add",data,{
        headers: {
          Authorization: token}
      })
      .then((success) => {
        
        notify(success.data.msg,success.data.status)
    
        if (success.data.status === 1) {
          
          e.target.reset();
          setselected_college(null)
        

        }
      })
    
      .catch((error) => {
        console.error('Error:', error);
      });
    
    
    }

    return (
      <div className='min-h-screen py-10 bg-gray-300'>
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
        <div className="text-center text-4xl font-bold mb-3"> Student Story Add</div>
        <form onSubmit={submit_handler} enctype="multipart/form-data">
        
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
            <div>
              <label htmlFor="college_id" className="block text-sm font-bold text-gray-700">
                College ID
              </label>
              <Select
                value={selected_college}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    padding: '4px 20px',
                    marginTop: '4px',
                  }),
                }}
                onChange={(e) => { setselected_college(e) }}
                name='college_id'
                options={colleges?.map(data => ({ value: data._id, label: data.college_name }))}
              />
            </div>


            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="heading">
              URL
              </label>
              <input
                id="url"
                name="url"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Youtube URL"
              />
            </div>


          
           
           
          </div>


         

          <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="heading">
              Title
              </label>
              <textarea
                id="title"
                name="title"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Title"
              />
            </div>

          

            <div className="flex justify-end mb-4 mt-2">
            <button
              type="submit"
              className="bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
          
        </form>
      </div>
    

      
     
    </div>
    

        )
}



