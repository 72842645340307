

import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select';
import { Context } from '../../../../Context_holder';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';

export default function Post_add() {

    const{college_fetch,colleges,selected_college,setselected_college,quill_value,setquill_value ,token,notify}=useContext(Context)
    










    useEffect(
        ()=>{
      
          college_fetch()
          setselected_college(null)
      
        
        },[]
      )
     

    const submit_handler = (e)=>{

        e.preventDefault();
        
        
    
     const logo= e.target.logo.files[0]
     const college_id= selected_college?.value
     const post= quill_value
     
    const author = e.target.author.value
    const heading = e.target.heading.value
    
      const formData = new FormData();
    
    
      formData.append('logo', logo);
      formData.append('college_id', college_id);
      formData.append('post', post);
      
      formData.append('author', author);
      formData.append('heading', heading);
    
      
     
    
      axios.post(process.env.REACT_APP_API_BASE_URL+process.env.REACT_APP_POST_URL+"add",formData,{
        headers: {
          Authorization: token}
      })
      .then((success) => {
        notify(success.data.msg,success.data.status)
    
        if (success.data.status === 1) {
         
    
          e.target.reset();
        
    
          setselected_college(null)
          setquill_value(null)
        
    
        }
      })
    
      .catch((error) => {
        console.error('Error:', error);
      });
    
    
    }

    return (
      <div className='min-h-screen py-10 bg-gray-300'>
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
        <div className="text-center text-4xl font-bold mb-3"> Post Add For Particuler</div>
        <form onSubmit={submit_handler} enctype="multipart/form-data">
        
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
            <div>
              <label htmlFor="college_id" className="block text-sm font-bold text-gray-700">
                College ID
              </label>
              <Select
                value={selected_college}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    padding: '4px 20px',
                    marginTop: '4px',
                  }),
                }}
                onChange={(e) => { setselected_college(e) }}
                name='college_id'
                options={colleges?.map(data => ({ value: data._id, label: data.college_name }))}
              />
            </div>


            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="logo">
                Image
              </label>
              <input
                type='file'
                id="logo"
                name="logo"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

          
           
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="author">
            Author Name
              </label>
              <input
                id="author"
                name="author"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Auther Name"
              />
            </div>
          </div>


         

          <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="heading">
                Heading
              </label>
              <textarea
                id="heading"
                name="heading"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Heading"
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="heading">
                Post
              </label>

             <div>
             <ReactQuill value={quill_value}  onChange={(e)=> setquill_value(e)}/></div> 

            
           
           
             

         
            </div>


            

            <div className="flex justify-end mb-4 mt-2">
            <button
              type="submit"
              className="bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
          
        </form>
      </div>
    

      
     
    </div>
    

        )
}
