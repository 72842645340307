

import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../Context_holder';
import Select from 'react-select';
import axios from 'axios';
export default function Doubts_add() {
  const{college_fetch,colleges ,selected_college,setselected_college,token,notify}=useContext(Context)



  


  useEffect(
    ()=>{
  
      college_fetch()
      setselected_college(null)
    
    },[]
  )
 


  const handleSubmit = (e) => {
    e.preventDefault();
  
    const college_id = selected_college?.value;
    const doubt = e.target.doubt.value;
    const answer = e.target.answer.value;
    
   
  
    const data = {
        college_id:college_id,
      doubt:doubt,
      answer:answer
    };

 

    axios.post(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_DOUBTS_URL}add`, data,{
      headers: {
        Authorization: token}
    })
    .then((response) => {
     
     notify(response.data.msg,response.data.status)

      if (response.data.status === 1) {
       
        e.target.reset();
        setselected_college(null)
      }
    })
    .catch((error) => {
       
      
      
      })
     
  };

  return (
    <div className="min-h-screen py-10 bg-gray-300">
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4">Add New FAQ For Particuler College</h2>
      <form onSubmit={handleSubmit} className="w-full">
  
        <div className="grid grid-cols-1  gap-6 mb-4">
          <div>
            <label htmlFor="college_id" className="block text-sm font-medium text-gray-700">College ID</label>
            <Select
              value={selected_college}
              styles={{
                control: (provided) => ({
                  ...provided,
                  padding: '4px 20px',
                  marginTop: '4px',
                }),
              }}
              onChange={(e) => { setselected_college(e) }}
              name="college_id"
              options={colleges?.map(data => ({ value: data._id, label: data.college_name }))}
            />
          </div>
          <div>
            <label htmlFor="" className="block text-sm font-medium text-gray-700">Doubt</label>
            <textarea
           
              id="doubt"
              name="doubt"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
          <div>
            <label htmlFor="" className="block text-sm font-medium text-gray-700">Answer</label>
            <textarea
             
              id="answer"
              name="answer"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
          
        </div>
  
       
  
        <div className="flex justify-end mb-4">
          <button
            type="submit"
            className="bg-blue-500 w-full md:w-auto hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Add Doubts
          </button>
        </div>
      </form>
    </div>
  </div>
  
  );
}

