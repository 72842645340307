import React from 'react'

export default function CollegeBanner2nd({banner}) {
  console.log(banner,"33333")
  return (
    <img 
   src={`${process.env.REACT_APP_API_BASE_URL}image/college_banners/${banner}`} 
  loading="lazy"
    alt="image load..." 

    />
  )
}
