import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { FaEye } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Website_stories = ({ websitestory }) => {
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeVideoUrl, setActiveVideoUrl] = useState(""); // To store the active video URL or video ID

  useEffect(() => {
    const updateSlidesToShow = () => {
      if (window.innerWidth < 640) {
        setSlidesToShow(2);
      } else if (window.innerWidth < 1024) {
        setSlidesToShow(3);
      } else {
        setSlidesToShow(3);
      }
    };

    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);
    return () => window.removeEventListener("resize", updateSlidesToShow);
  }, []);

  const handleThumbnailClick = (url) => {
    console.log("Thumbnail clicked! URL: ", url); // Log the URL clicked
    if (!url) {
      console.error("Video URL is undefined or empty");
      return; // Prevent opening modal if URL is invalid
    }
    if (url.includes("v=")) {
      // Extract the video ID from the URL
      const videoId = url.split("v=")[1].split("&")[0];
      console.log("Extracted Video ID: ", videoId); // Log the extracted video ID
      setActiveVideoUrl(videoId); // Set the extracted video ID
      setIsModalOpen(true); // Open modal with video
    } else {
      console.error("Invalid YouTube URL:", url); // Log error for invalid URL
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    nextArrow: <div className="slick-arrow">›</div>,
    prevArrow: <div className="slick-arrow">‹</div>,
  };

  return (
    <div className="relative w-full flex flex-col justify-center items-center mx-auto p-4 mt-[2rem]">
      <div className="text-start text-[1rem] ml-4 w-[90%]">
        <div>Trusted Voices</div>
        <div className="font-bold md:text-[1.2rem] text-md text-blue-300">Your Source for Authentic Insights</div>
      </div>

      {/* Slider for stories */}
      <Slider {...settings} className="w-[90%] mt-4">
        {websitestory?.map((slide, index) => (
          <div key={index} className="px-2">
            {/* Check if video_url exists */}
            {slide.video_url ? (
              <div className="bg-gray-800 rounded-xl overflow-hidden shadow-lg h-60 w-full">
                <div className="bg-black h-8 w-full flex items-center justify-between px-2">
                  <div className="flex items-center">
                    <FaEye className="text-white mr-1 text-lg" />
                    <span className="text-white text-sm">{slide.views}</span>
                  </div>
                </div>

                {/* Thumbnail Image */}
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}image/story_thumbnail/${slide.thumbnail}`}
                  loading="lazy"
                  alt="thumbnail"
                  className="h-full w-full object-cover cursor-pointer"
                  onClick={() => handleThumbnailClick(slide.video_url)}  // Handle click event
                />
                <div className="bg-black h-8 w-full"></div>
              </div>
            ) : (
              <p>No video URL available</p> // Handle missing video_url case
            )}
          </div>
        ))}
      </Slider>

      {/* Modal to show YouTube video */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg w-11/12 sm:w-2/3 lg:w-1/2 relative">
            {/* Close button */}
            <button 
              onClick={() => setIsModalOpen(false)} 
              className="absolute top-1 right-2 font-bold text-black text-xl"
            >
              X
            </button>

            {/* YouTube iframe embed, use video ID */}
            <iframe
              width="100%"
              height="400"
              src={`https://www.youtube.com/embed/${activeVideoUrl}`}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default Website_stories;
