




import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../Context_holder';
import Select from 'react-select';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactStarsRating from 'react-awesome-stars-rating';



export default function Rating_add() {
  const{college_fetch,colleges ,selected_college,setselected_college,notify,}=useContext(Context)

const[ rating_value,setrating_value]= useState(null)

  const onChange = (value) => {
    setrating_value(value)

  };




useEffect(
    ()=>{
college_fetch()
    },[]
)


const handleSubmit=()=>{

    const college_id= selected_college?.value
    const rating= rating_value


    const data= {
        college_id:college_id,
        rating:rating,
    }


    axios.post(process.env.REACT_APP_API_BASE_URL+process.env.REACT_APP_RATING_URL+"add",data)
    .then((success) => {
      
      notify(success.data.msg,success.data.status)
      
  
      if (success.data.status === 1) {
       
  
  
        setselected_college(null)
        setrating_value(null)
      
     
  
      }
    })
  
    .catch((error) => {
      console.error('Error:', error);
    });
  



}


  return (
    <div className="min-h-screen py-10 bg-gray-300">
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4"> Rating For College</h2>
      

      <div>
      <label htmlFor="college_id" className="block text-sm font-medium text-gray-700">College ID</label>
      <Select
        value={selected_college}
        styles={{
          control: (provided) => ({
            ...provided,
            padding: '4px 20px',
            marginTop: '4px',
          }),
        }}
        onChange={(e) => { setselected_college(e) }}
        name="college_id"
        options={colleges?.map(data => ({ value: data._id, label: data.college_name }))}
      />
    </div>


      <div className='my-3'> Rating</div>
      <ReactStarsRating onChange={onChange}   value={rating_value}  className=" flex "/>
    
     
   
    
      <button
              
              className="bg-blue-500 my-3 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={handleSubmit}
            >
              Submit
            </button>
    </div>
  </div>
  
  );
}


